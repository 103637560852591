import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.newsletter_el = this.element.parentNode;
    this.mainContainer = this.newsletter_el.parentNode;
    this.originalIndex = this.currentIndex();
    this.footer = document.querySelector("footer.footer");
    if (this.shouldObserve()) this.observe();
  }

  // Determines if this newsletter needs an IntersectionObserver.
  shouldObserve() {
    return (
      !this.element.classList.contains("global-newsletter") &&
      !this.element.classList.contains("modal-newsletter") &&
      !this.element.parentNode.classList.contains("sidebar")
    );
  }

  observe() {
    const stickyOptions = { threshold: 1 };
    const footerOptions = { threshold: 0.8 };

    if (
      !("IntersectionObserver" in window) ||
      !("IntersectionObserverEntry" in window) ||
      !("intersectionRatio" in window.IntersectionObserverEntry.prototype)
    ) {
      this.element.classList.add("sticky");
    } else {
      this.footerObserver = new IntersectionObserver(
        this.footerWatcher.bind(this),
        footerOptions
      );
      this.footerObserver.observe(this.footer);

      this.stickyObserver = new IntersectionObserver(
        this.stickyWatcher.bind(this),
        stickyOptions
      );
      this.stickyObserver.observe(this.element);
    }
  }

  stickyWatcher(entries, observer) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.stickify();
        observer.unobserve(entry.target);
      }
    });
  }

  footerWatcher(entries, observer) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.footerVisible();
        observer.unobserve(entry.target);
      }
    });
  }

  footerVisible() {
    // move newsletter module into bottom of main#content and unsticky
    const alreadyApplied = this.newsletter_el.classList.contains(
      "footer-visible"
    );
    const isSticky = this.newsletter_el.classList.contains("sticky");
    if (!alreadyApplied && isSticky) {
      this.newsletter_el.classList.add("footer-visible");
      this.newsletter_el.style.position = `relative`;
      this.footer.parentNode.insertBefore(this.newsletter_el, this.footer);
      this.unsticky();
    }
  }

  currentIndex() {
    return Array.from(this.mainContainer.children).indexOf(this.newsletter_el);
  }

  stickify() {
    if (!this.newsletter_el.classList.contains("footer-visible")) {
      this.newsletter_el.classList.add("sticky");
    }
  }

  unsticky() {
    this.footer.parentNode.insertBefore(this.newsletter_el, this.footer);
    this.newsletter_el.classList.remove("sticky");
  }
}
