import { Controller } from "stimulus";
import { parentModal, loadModalUrl } from "../modal/utils";

import { submitForm } from "init/ajax";

export default class extends Controller {
  connect() {}

  async backToEmail(event) {
    event.preventDefault();

    loadModalUrl(event.target.href, { replace: this.element });
  }

  async submit(event) {
    event.preventDefault();

    const form = event.target;
    // Consider everything valid if browser validations aren't supported
    if (!form.reportValidity || form.reportValidity()) {
      let response = await submitForm(form);
      let responseHTML = response.html;

      if(response.context) {
        if (window._learnq) {
          const context_klaviyo = JSON.parse(response.context.context);
          _learnq.push(['identify', {
            '$email': response.context.email
          }]);
          setTimeout(() => {
            _learnq.push(['track', 'create-account', context_klaviyo]);
          }, 100);

        }
      }

      parentModal(this.element).replaceWith(
        responseHTML || ""
      );
    }
  }
}
