import { Controller } from "stimulus";
import { submitForm } from "init/ajax";

export default class extends Controller {
  static targets = ["form", "submit", "token"];

  connect() {
    this.originalForm = this.formTarget.innerHTML;
    this.inFlight = false;
  }

  validate() {
    let valid = true;
    let requiredFields = this.formTarget.querySelectorAll("[required]");

    requiredFields.forEach(field => {
      let error_msg = field.parentNode.querySelector(".error-msg");
      if (field.checkValidity()) {
        field.parentNode.classList.remove("error");
        if (error_msg) error_msg.parentNode.removeChild(error_msg);
      } else {
        let parent = field.parentNode;
        let child = error_msg ? error_msg : document.createElement("div");
        let msg = field.validationMessage;
        child.innerHTML = `<span class="error-msg">${msg}</span>`;
        parent.classList.add("error");
        parent.appendChild(child);
        valid = false;
      }
    });
    return valid;
  }

  scrollToFirstError() {
    const el = this.formTarget.querySelector(".error");
    const elTopDistance = window.pageYOffset + el.getBoundingClientRect().top;
    const headerHeight = document
      .querySelector("body > .header")
      .getBoundingClientRect().height;
    const headerScrollGap = 32;
    window.scrollTo({
      top: elTopDistance - headerHeight - headerScrollGap,
      behavior: "smooth"
    });
  }

  async submit(e) {
    e.preventDefault();
    e.returnValue = false;

    if (this.inFlight) {
      return;
    }
    const token = await window.grecaptcha.enterprise.execute('6LcT6F4iAAAAAK_SIlVjZSBjS88mT6bQ70jiOYS5', {action: 'CONTACT'});
    this.tokenTarget.value = token;

    let valid = this.validate();
    if (!valid) {
      this.scrollToFirstError();
      return;
    } else {
      this.disableSubmit();

      submitForm(this.formTarget).then(response => {
        if (response.success == "true") {
          let $this = this;
          this.element.classList.add("success");
          this.formTarget.innerHTML = this.originalForm;
          this.inFlight = false;
          setTimeout(() => {
            $this.element.classList.remove("success");
          }, 5000);
        } else {
          this.formTarget.innerHTML = `
            <strong>Sorry, there was an error with your request</strong>
          `;
        }
      });
    }
  }

  disableSubmit() {
    this.submitTarget.disabled = true;
    this.inFlight = true;
  }
}
